<script id="alfa-payment-script"
        type="text/javascript"
        src="https://testpay.alfabank.ru/assets/alfa-payment.js">
</script>


<div class="d-sm-inline d-md-inline d-lg-none">
  <img class="position-absolute" alt="loginBg" style=" width: 100%; height: auto; object-fit: fill;" src="/images/login-bg-mobile.svg" />
  <div class="row">
    <div class="col-12" style="margin-top:5vh;margin-bottom: 5vh;text-align: center;">
      <a href="/#main" class="">
        <img src="/images/logo.svg" alt="logo" style="width: 20em;" />
      </a>
    </div>
  </div>
  <div class="" >
    <mat-card class="register-container-card-table register-container-card-mobile">

      <div class="">
        
        

        
        
        <div  class=""  style="" errorMessage="">
          <div class="" style="text-align:center;"><img class="register-icon-table register-icon-mobile" src="/images/registration-icon-mobile.svg" alt="profileIcon" style="" /><span class="profile-title-table profile-title-mobile">{{'listener_data' | translate}}</span></div>
          <form [formGroup]="secondFormGroup">
            <div class="welcom-text-table welcom-text-mobile" style="margin-top: 2.2vh;">{{'welcome' | translate}}</div>
            
            <div fxLayout="column" class="" fxLayoutAlign="">

              <div fxFlex="100" class="input-mobile input-mobile-2">
                <div>
                  <div *ngIf="campaign?.course && campaign?.currency" fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                    <div class="mat-display-1 mb-2 accent-color pay-description">{{campaign?.course?.name}}</div>
                    <div fxFlex class="course-reg">
                      <pre *ngIf="currentCountry==='Казахстан'|| currentCountry==='Другая'" class="mat-body">{{campaign?.course?.description}}</pre>
                      <pre class="mat-body" *ngIf="currentCountry==='Россия'">Онлайн оплата курса временно невозможна. Пожалуйста, свяжитесь с нами для получения информации.</pre>
                      <hr class="delim w-100" />
                      <div class="mat-body">
                        {{'price' | translate}}: <b>{{campaign?.price}} {{campaign?.currency?.isoCode}} {{campaign?.currency?.symbol}}</b>

                      </div>
                      <div *ngIf="currentCountry==='Казахстан'|| currentCountry==='Другая'" class="cards-desk">
                        <img src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
                        <img src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--<div *ngIf="currentCountry==='KZ'">
    <div *ngIf="campaign?.course" fxLayout="column h-100" fxLayoutAlign="space-between stretch">
      <div class="mat-display-1 mb-2 accent-color pay-description">{{campaign?.course?.name}}</div>
      <div fxFlex class="course-reg">
        <pre class="mat-body">{{campaign?.course?.description}}</pre>
        <hr class="delim w-100" />
        <div class="mat-body">{{'price' | translate}}: <b>{{campaign?.price | currency:'KZT' : 'symbol' :'1.2-2'}}</b></div>
      </div>
    </div>
  </div>-->

                <!--<button *ngIf="campaign?.course " style="margin-bottom: 5vh;" mat-raised-button mat-flat-button color="secondary"
                        class="b-primary b-registration-table b-registration-mobile" (click)="doPayment()">
                  {{'Proceed_to_checkout' | translate}}
                </button>-->
                <button *ngIf="(campaign?.course) && (currentCountry === 'Казахстан' || currentCountry === 'Другая')" style="margin-bottom: 5vh;" mat-raised-button mat-flat-button color="secondary"
                        class="b-primary b-registration-table b-registration-mobile" (click)="doPayment()">
                  {{'Proceed_to_checkout' | translate}}
                </button>
              </div>
              <div class="welcom-text-small-table welcom-text-small-mobile" style="margin-top: 0.075vh;">{{'fill_data' | translate}}</div>
              <div fxFlex="100" fxLayout="column" class="input-mobile input-mobile-2">
                <div class="reg-label">{{'Name' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                  <input class="clientInfo" formControlName='name'
                         [(ngModel)]='contact.firstName'
                         floatLabel='never' required matInput />
                  <mat-error class="no-margin" *ngIf="checkProfileError('name', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Name' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Family' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                  <input class="clientInfo" formControlName='family'
                         [(ngModel)]='contact.lastName'
                         required matInput />
                  <mat-error class="no-margin" *ngIf="checkProfileError('family', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Family' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Email' | translate}}</div>
                <mat-form-field class="w-100 no-bottom" floatLabel='never' appearance="outline">
                  <input type="email"
                         [(ngModel)]='contact.email'
                         formControlName='email' required matInput />
                  <!--mat-error *ngIf="checkProfileError('email', 'required')">
          {{'enter_data' | translate}} <strong>{{'Email' | translate}}</strong>
      </mat-error-->
                </mat-form-field>
                <div class="error-color mat-body-2">{{'required_for_passing_the_exam' | translate}}</div>

                <div *ngIf="contact" style="margin-top: 1vh;">
                  <div class="reg-label">{{'phone_number' | translate}}</div>
                  <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                    <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
                           placeHolderCharacter="_" name="phone_number" #pnumber
                           [readonly]="contact.mobilePhone != null"
                           aria-describedby="name"
                           formControlName='mobilePhone'
                           [style.color]="contact.mobilePhone != null ? '#6A6A6A' : '#000000'"
                           floatLabel='never' matInput
                           class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __" />
                  </mat-form-field>
                </div>
              </div>

              <div fxFlex="100" fxLayout="column" class="input-mobile input-mobile-2">
                <div class="reg-label">{{'Occupation' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='occupation' required matInput floatLabel='never' [matAutocomplete]="autoOccupation" />
                  <mat-autocomplete (optionSelected)="onOccupationChanged($event)" #autoOccupation="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredOccupations" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('occupation', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Occupation' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Country' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='country' required matInput floatLabel='never' [matAutocomplete]="autoCountries" />
                  <mat-autocomplete (optionSelected)="onCountryChanged($event)" #autoCountries="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredCountries" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('country', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Country' | translate}}</strong>
                  </mat-error>
                </mat-form-field>
                <div class="reg-label">{{'City' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='city' matInput type="text" floatLabel='never' [matAutocomplete]="autoCities" />
                  <mat-autocomplete (optionSelected)="onCityChanged($event)" #autoCities="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredCities" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('city', 'required')">
                    {{'enter_data' | translate}} <strong>{{'City' | translate}}</strong>
                  </mat-error>
                </mat-form-field>
                <div class="reg-label">{{'Personal_information' | translate}}</div>
                <mat-form-field class="w-100" fxFlex appearance="outline">
                  <textarea matInput
                            [(ngModel)]='contact.description'
                            formControlName='personalInformation' cdkTextareaAutosize
                            cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4"></textarea>
                </mat-form-field>

              </div>
              <button mat-raised-button mat-flat-button color="secondary"
                      class="b-secondary-table b-registration-table b-registration-mobile-2" (click)="saveProfile()">
                {{'save_profile' | translate}}
              </button>

            </div>
            
              
              
            
          </form>
        </div>
        <!--<mat-step state="payment" label="{{'payment' | translate}}" errorMessage="">
          <div *ngIf="!isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'You_pay' | translate}}</div>
          <div *ngIf="!isPayed" fxLayout="row" fxLayoutAlign="space-between stretch">
            <div fxFlex="62" fxLayout="column">
              <div class="mat-subheading-1 dark-accent-text-color" style="margin-top: 0.075vh;">{{'Payment_amount' | translate}} {{ campaign?.price }} {{ campaign?.currency?.symbol }}</div>
              <div class="reg-label dark-text-color">{{'card_number' | translate}}</div>
              <mat-form-field class="w-50 no-bottom" appearance="outline">
                <input type="tel" mask="0000 0000 0000 0000" [showMaskTyped]="false"
                       placeHolderCharacter="X" name="cnumber" #cnumber matInput
                       [dropSpecialCharacters]="true"
                       [validation]="true"
                       [(ngModel)]='buyModel.creditCard.cardNumber'
                       class="w-100" placeholder="XXXX XXXX XXXX XXXX"
                       required />-->
                <!--div class="mat-body-2" *ngIf="isError">
                    {{errorMessage}}
                </div-->
              <!--</mat-form-field>
              <div class="reg-label dark-text-color">{{'cardholder_name' | translate}}</div>
              <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <mat-form-field fxFlex="50" class="w-100 no-bottom" appearance="outline">
                  <input [(ngModel)]='buyModel.creditCard.holderName'
                         required matInput floatLabel='never' />
                </mat-form-field>
                <div class="mat-caption hint-color" fxFlex="48" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cardholder_desc' | translate}}</div></div>
              </div>

              <div fxLayout="row" class="w-50" fxLayoutAlign="start stretch">
                <div fxFlex="54" class="reg-label dark-text-color">{{'validity' | translate}}</div>
                <div fxFlex class="reg-label dark-text-color">{{'cvv' | translate}}</div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <div class="w-50" fxLayout="row" fxLayoutAlign="start stretch">
                  <mat-form-field class="card-date no-bottom" appearance="outline">
                    <input type="tel"
                           class="date-input"
                           mask="00" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityMonth" #validityMonth matInput
                           [(ngModel)]='buyModel.creditCard.expirationMonth'
                           placeholder="{{'mm' | translate}}"
                           required />
                  </mat-form-field>
                  <div class="card-date-delimiter mat-body-1">/</div>
                  <mat-form-field class="card-date no-bottom" appearance="outline">
                    <input type="tel"
                           class="date-input"
                           mask="00" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityYear" #validityYear matInput
                           placeholder="{{'yy' | translate}}"
                           [(ngModel)]='buyModel.creditCard.expirationYear'
                           required />
                  </mat-form-field>

                  <mat-form-field class="no-bottom cvv" appearance="outline">
                    <input type="tel"
                           mask="000" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityCvv" #validityCvv matInput
                           placeholder="XXX"
                           [(ngModel)]='buyModel.creditCard.cvv'
                           required />
                  </mat-form-field>
                </div>
                <div fxFlex="48" class="mat-caption hint-color" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cvv_desc' | translate}}</div></div>
              </div>

              <div class="payments-methods" fxLayout="row" fxLayoutAlign="start center">
                <div class="hint-color">{{'accept_for_payment' | translate}}</div>
                <img style="margin-left:7px;" src="/images/Visa.png" alt="Visa" />
                <img style="margin-left:14px;" src="/images/Mastercard.png" alt="Mastercard" />
              </div>

              <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
                <button mat-raised-button mat-flat-button color="secondary"
                        class="b-primary b-registration" (click)="doPayment()">
                  {{'pay' | translate}}
                </button>
              </div>
            </div>

            <div fxFlex="31">
              <div fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                <div class="mat-display-1 mb-2 accent-color">{{campaign?.course?.name}}</div>
                <div fxFlex class="course-reg">
                  <pre class="mat-body">{{campaign?.course?.description}}</pre>
                  <hr class="delim w-100" />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'Вы оплатили' | translate}}</div>
          <div *ngIf="isPayed" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="my-4" fxLayoutAlign="start center">
              <img src="/images/complete.svg" alt="complete" />
              <div fxFlex class="ml-3 reg-label dark-text-color font-weight-bold">{{'Оплата прошла успешно' | translate}}</div>
            </div>
            <div class="reg-label dark-text-color">{{'Вы оплатили' | translate}} {{campaign?.course?.name}}</div>
            <div class="reg-label dark-text-color mt-2">{{'Сумма оплаты:' | translate}} <b>{{ campaign?.price }} {{ campaign?.currency?.symbol }}</b></div>
            <a class="reg-label dark-text-color mt-4 cursorpointer">{{'Информация об оплате' | translate}}</a>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
              <button mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="goToCabinet()">
                {{'Начать обучение' | translate}}
              </button>
            </div>
          </div>
        </mat-step>-->

      </div>
    </mat-card>
  </div>
</div>


<div class="d-none d-lg-inline">
  <img class="position-fixed h-100 w-100" alt="logingBG" src="/images/login_bg.svg" />
  <a href="/#main" class="logo-img">
    <img src="/images/logo.svg" alt="logo" style="width: 8em;" />
  </a>
  <div fxLayout="row"
       fxFlexFill
       fxLayoutAlign="center center">
    <mat-card class="register-card">
      <mat-horizontal-stepper #stepper>
        <ng-template matStepperIcon="registration"><img src="/images/edit.svg" alt="edit" /></ng-template>
        <ng-template matStepperIcon="listener_data"><img src="/images/id-card.svg" alt="idCard" /></ng-template>
        <ng-template matStepperIcon="payment"><img src="/images/credit-card.svg" alt="creditCard" /></ng-template>

        <mat-step completed="false" editable="false" state="registration" label="{{'registration' | translate}}" errorMessage="">
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" state="listener_data" label="{{'listener_data' | translate}}" errorMessage="">
          <form [formGroup]="secondFormGroup">
            <div class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'welcome' | translate}}</div>
            <div class="mat-body dark-text-color" style="margin-top: 0.075vh;">{{'fill_data' | translate}}</div>
            <div fxLayout="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="31" fxLayout="column">
                <div class="reg-label">{{'Name' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                  <input class="clientInfo" formControlName='name'
                         [(ngModel)]='contact.firstName'
                         floatLabel='never' required matInput />
                  <mat-error class="no-margin" *ngIf="checkProfileError('name', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Name' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Family' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                  <input class="clientInfo" formControlName='family'
                         [(ngModel)]='contact.lastName'
                         required matInput />
                  <mat-error class="no-margin" *ngIf="checkProfileError('family', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Family' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Email' | translate}}</div>
                <mat-form-field class="w-100 no-bottom" floatLabel='never' appearance="outline">
                  <input type="email"
                         [(ngModel)]='contact.email'
                         formControlName='email' required matInput />
                  <!--mat-error *ngIf="checkProfileError('email', 'required')">
                      {{'enter_data' | translate}} <strong>{{'Email' | translate}}</strong>
                  </mat-error-->
                </mat-form-field>
                <div class="error-color mat-body-2">{{'required_for_passing_the_exam' | translate}}</div>

                <div *ngIf="contact" style="margin-top: 1vh;">
                  <div class="reg-label">{{'Phone_number' | translate}}</div>
                  <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                    <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
                           placeHolderCharacter="_" name="phone_number" #pnumber
                           [readonly]="contact.mobilePhone != null"
                           aria-describedby="name"
                           formControlName='mobilePhone'
                           [style.color]="contact.mobilePhone != null ? '#6A6A6A' : '#000000'"
                           floatLabel='never' matInput
                           class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __" />
                  </mat-form-field>
                </div>
              </div>

              <div fxFlex="31" fxLayout="column">
                <div class="reg-label">{{'Occupation' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='occupation' required matInput floatLabel='never' [matAutocomplete]="autoOccupation" />
                  <mat-autocomplete (optionSelected)="onOccupationChanged($event)" #autoOccupation="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredOccupations" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('occupation', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Occupation' | translate}}</strong>
                  </mat-error>
                </mat-form-field>

                <div class="reg-label">{{'Country' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='country' required matInput floatLabel='never' [matAutocomplete]="autoCountries" />
                  <mat-autocomplete (optionSelected)="onCountryChanged($event)" #autoCountries="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredCountries" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('country', 'required')">
                    {{'enter_data' | translate}} <strong>{{'Country' | translate}}</strong>
                  </mat-error>
                </mat-form-field>
                <div class="reg-label">{{'City' | translate}} <span class="red-star">*</span></div>
                <mat-form-field class="w-100" appearance="outline">
                  <input formControlName='city' matInput type="text" floatLabel='never' [matAutocomplete]="autoCities" />
                  <mat-autocomplete (optionSelected)="onCityChanged($event)" #autoCities="matAutocomplete" [displayWith]="displayName">
                    <mat-option *ngFor="let item of filteredCities" [value]="item">
                      {{item.name}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="checkProfileError('city', 'required')">
                    {{'enter_data' | translate}} <strong>{{'City' | translate}}</strong>
                  </mat-error>
                </mat-form-field>
                <div class="reg-label">{{'Personal_information' | translate}}</div>
                <mat-form-field class="w-100" fxFlex appearance="outline">
                  <textarea matInput
                            [(ngModel)]='contact.description'
                            formControlName='personalInformation' cdkTextareaAutosize
                            cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4"></textarea>
                </mat-form-field>
              </div>

              <div fxFlex="31">
                <div>
                  <div *ngIf="campaign?.course && campaign?.currency" fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                    <div class="mat-display-1 mb-2 accent-color pay-description">{{campaign?.course?.name}}</div>
                    <div fxFlex class="course-reg">
                      
                      <pre *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'" class="mat-body">{{campaign?.course?.description}}</pre>
                      <pre class="mat-body" *ngIf="currentCountry==='Россия'">Онлайн оплата курса временно невозможна. Пожалуйста, свяжитесь с нами для получения информации.</pre>
                      <hr class="delim w-100" />
                      <div class="mat-body">
                        {{'price' | translate}}: <b>{{campaign?.price}} {{campaign?.currency?.isoCode}} {{campaign?.currency?.symbol}}</b>

                      </div>
                      <div *ngIf="currentCountry==='Казахстан' || currentCountry==='Другая'" class="cards-desk">
                        <img src="/images/mastercard.svg" alt="mastercardLogo" class="footer-contact-img-img-paymentcard-mastercard" />
                        <img src="/images/visa.svg" alt="visaLogo" class="footer-contact-img-img-paymentcard-visa" />
                      </div>
                    </div>
                  </div>
                </div>


                <!--<div *ngIf="currentCountry==='KZ'">
    <div *ngIf="campaign?.course" fxLayout="column h-100" fxLayoutAlign="space-between stretch">
      <div class="mat-display-1 mb-2 accent-color pay-description">{{campaign?.course?.name}}</div>
      <div fxFlex class="course-reg">
        <pre class="mat-body">{{campaign?.course?.description}}</pre>
        <hr class="delim w-100" />
        <div class="mat-body">{{'price' | translate}}: <b>{{campaign?.price | currency:'KZT' : 'symbol' :'1.2-2'}}</b></div>
      </div>
    </div>
  </div>-->

              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 2vh;">
              <button mat-raised-button mat-flat-button color="secondary"
                      class="b-secondary b-registration" (click)="saveProfile()">
                {{'save_profile' | translate}}
              </button>
              <!--<button *ngIf="campaign?.course" mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="doPayment()">
                {{'Proceed_to_checkout' | translate}}
              </button>-->
              <button *ngIf="(campaign?.course) && (currentCountry === 'Казахстан' || currentCountry === 'Другая')"
                      mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="doPayment()">
                {{ 'Proceed_to_checkout' | translate }}
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step state="payment" label="{{'payment' | translate}}" errorMessage="">
          <div *ngIf="!isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'You_pay' | translate}}</div>
          <div *ngIf="!isPayed" fxLayout="row" fxLayoutAlign="space-between stretch">
            <div fxFlex="62" fxLayout="column">
              <div class="mat-subheading-1 dark-accent-text-color" style="margin-top: 0.075vh;">{{'Payment_amount' | translate}} {{ campaign?.price }} {{ campaign?.currency?.symbol }}</div>
              <div class="reg-label dark-text-color">{{'card_number' | translate}}</div>
              <mat-form-field class="w-50 no-bottom" appearance="outline">
                <input type="tel" mask="0000 0000 0000 0000" [showMaskTyped]="false"
                       placeHolderCharacter="X" name="cnumber" #cnumber matInput
                       [dropSpecialCharacters]="true"
                       [validation]="true"
                       [(ngModel)]='buyModel.creditCard.cardNumber'
                       class="w-100" placeholder="XXXX XXXX XXXX XXXX"
                       required />
                <!--div class="mat-body-2" *ngIf="isError">
                    {{errorMessage}}
                </div-->
              </mat-form-field>
              <div class="reg-label dark-text-color">{{'cardholder_name' | translate}}</div>
              <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <mat-form-field fxFlex="50" class="w-100 no-bottom" appearance="outline">
                  <input [(ngModel)]='buyModel.creditCard.holderName'
                         required matInput floatLabel='never' />
                </mat-form-field>
                <div class="mat-caption hint-color" fxFlex="48" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cardholder_desc' | translate}}</div></div>
              </div>

              <div fxLayout="row" class="w-50" fxLayoutAlign="start stretch">
                <div fxFlex="54" class="reg-label dark-text-color">{{'validity' | translate}}</div>
                <div fxFlex class="reg-label dark-text-color">{{'cvv' | translate}}</div>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <div class="w-50" fxLayout="row" fxLayoutAlign="start stretch">
                  <mat-form-field class="card-date no-bottom" appearance="outline">
                    <input type="tel"
                           class="date-input"
                           mask="00" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityMonth" #validityMonth matInput
                           [(ngModel)]='buyModel.creditCard.expirationMonth'
                           placeholder="{{'mm' | translate}}"
                           required />
                  </mat-form-field>
                  <div class="card-date-delimiter mat-body-1">/</div>
                  <mat-form-field class="card-date no-bottom" appearance="outline">
                    <input type="tel"
                           class="date-input"
                           mask="00" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityYear" #validityYear matInput
                           placeholder="{{'yy' | translate}}"
                           [(ngModel)]='buyModel.creditCard.expirationYear'
                           required />
                  </mat-form-field>

                  <mat-form-field class="no-bottom cvv" appearance="outline">
                    <input type="tel"
                           mask="000" [showMaskTyped]="false"
                           placeHolderCharacter="X" name="validityCvv" #validityCvv matInput
                           placeholder="XXX"
                           [(ngModel)]='buyModel.creditCard.cvv'
                           required />
                  </mat-form-field>
                </div>
                <div fxFlex="48" class="mat-caption hint-color" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cvv_desc' | translate}}</div></div>
              </div>

              <div class="payments-methods" fxLayout="row" fxLayoutAlign="start center">
                <div class="hint-color">{{'accept_for_payment' | translate}}</div>
                <img style="margin-left:7px;" src="/images/Visa.png" alt="Visa" />
                <img style="margin-left:14px;" src="/images/Mastercard.png" alt="Mastercard" />
              </div>

              <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
                <button mat-raised-button mat-flat-button color="secondary"
                        class="b-primary b-registration" (click)="doPayment()">
                  {{'pay' | translate}}
                </button>
              </div>
            </div>

            <div fxFlex="31">
              <div fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                <div class="mat-display-1 mb-2 accent-color">{{campaign?.course?.name}}</div>
                <div fxFlex class="course-reg">
                  <pre class="mat-body">{{campaign?.course?.description}}</pre>
                  <hr class="delim w-100" />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'Вы оплатили' | translate}}</div>
          <div *ngIf="isPayed" fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row" class="my-4" fxLayoutAlign="start center">
              <img src="/images/complete.svg" alt="complete" />
              <div fxFlex class="ml-3 reg-label dark-text-color font-weight-bold">{{'Оплата прошла успешно' | translate}}</div>
            </div>
            <div class="reg-label dark-text-color">{{'Вы оплатили' | translate}} {{campaign?.course?.name}}</div>
            <div class="reg-label dark-text-color mt-2">{{'Сумма оплаты:' | translate}} <b>{{ campaign?.price }} {{ campaign?.currency?.symbol }}</b></div>
            <a class="reg-label dark-text-color mt-4 cursorpointer">{{'Информация об оплате' | translate}}</a>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
              <button mat-raised-button mat-flat-button color="secondary"
                      class="b-primary b-registration" (click)="goToCabinet()">
                {{'Начать обучение' | translate}}
              </button>
            </div>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
    </mat-card>
  </div>
</div>
<!--<img class="position-fixed h-100 w-100" alt="logingBG" src="/images/login_bg.svg" />
<a href="/#main" class="logo-img">
    <img src="/images/logo.svg" alt="logo" style="width: 8em;" />
</a>
<div fxLayout="row"
     fxFlexFill
     fxLayoutAlign="center center">
    <mat-card class="register-card">
        <mat-horizontal-stepper #stepper>
            <ng-template matStepperIcon="registration"><img src="/images/edit.svg" alt="edit" /></ng-template>
            <ng-template matStepperIcon="listener_data"><img src="/images/id-card.svg" alt="idCard"/></ng-template>
            <ng-template matStepperIcon="payment"><img src="/images/credit-card.svg" alt="creditCard" /></ng-template>

            <mat-step completed="false" editable="false" state="registration" label="{{'registration' | translate}}" errorMessage="">
            </mat-step>
            <mat-step [stepControl]="secondFormGroup" state="listener_data" label="{{'listener_data' | translate}}" errorMessage="">
                <form [formGroup]="secondFormGroup">
                    <div class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'welcome' | translate}}</div>
                    <div class="mat-body dark-text-color" style="margin-top: 0.075vh;">{{'fill_data' | translate}}</div>
                    <div fxLayout="row" fxLayoutAlign="space-between stretch">
                        <div fxFlex="31" fxLayout="column">
                            <div class="reg-label">{{'Name' | translate}} <span class="red-star">*</span></div>
                            <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                                <input class="clientInfo" formControlName='name'
                                       [(ngModel)]='contact.firstName'
                                       floatLabel='never' required matInput />
                                <mat-error class="no-margin" *ngIf="checkProfileError('name', 'required')">
                                    {{'enter_data' | translate}} <strong>{{'Name' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>

                            <div class="reg-label">{{'Family' | translate}} <span class="red-star">*</span></div>
                            <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                                <input class="clientInfo" formControlName='family'
                                       [(ngModel)]='contact.lastName'
                                       required matInput />
                                <mat-error class="no-margin" *ngIf="checkProfileError('family', 'required')">
                                    {{'enter_data' | translate}} <strong>{{'Family' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>

                            <div class="reg-label">{{'Email' | translate}}</div>
                            <mat-form-field class="w-100 no-bottom" floatLabel='never' appearance="outline">
                                <input type="email"
                                       [(ngModel)]='contact.email'
                                       formControlName='email' required matInput />-->
                                <!--mat-error *ngIf="checkProfileError('email', 'required')">
            {{'enter_data' | translate}} <strong>{{'Email' | translate}}</strong>
        </mat-error-->
                            <!--</mat-form-field>
                            <div class="error-color mat-body-2">{{'required_for_passing_the_exam' | translate}}</div>

                            <div *ngIf="contact" style="margin-top: 1vh;">
                                <div class="reg-label">{{'phone_number' | translate}}</div>
                                <mat-form-field class="w-100" floatLabel='never' appearance="outline">
                                    <input type="tel" prefix="{{prefix}}" mask=" 000 000 00 00" [showMaskTyped]="true"
                                            placeHolderCharacter="_" name="phone_number" #pnumber
                                            [readonly]="contact.mobilePhone != null"
                                            aria-describedby="name"
                                             formControlName='mobilePhone'
                                            [style.color]="contact.mobilePhone != null ? '#6A6A6A' : '#000000'"
                                            floatLabel='never' matInput
                                            class="phone-input w-100" [(ngModel)]="phone_number" placeholder="+7 (___) ___ __ __" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxFlex="31" fxLayout="column">
                            <div class="reg-label">{{'Occupation' | translate}} <span class="red-star">*</span></div>
                            <mat-form-field class="w-100" appearance="outline">
                                <input formControlName='occupation' required matInput floatLabel='never' [matAutocomplete]="autoOccupation" />
                                <mat-autocomplete (optionSelected)="onOccupationChanged($event)" #autoOccupation="matAutocomplete" [displayWith]="displayName">
                                    <mat-option *ngFor="let item of filteredOccupations" [value]="item">
                                        {{item.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="checkProfileError('occupation', 'required')">
                                    {{'enter_data' | translate}} <strong>{{'Occupation' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>

                            <div class="reg-label">{{'Country' | translate}} <span class="red-star">*</span></div>
                            <mat-form-field class="w-100" appearance="outline">
                                <input formControlName='country' required matInput floatLabel='never' [matAutocomplete]="autoCountries" />
                                <mat-autocomplete (optionSelected)="onCountryChanged($event)" #autoCountries="matAutocomplete" [displayWith]="displayName">
                                    <mat-option *ngFor="let item of filteredCountries" [value]="item">
                                        {{item.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="checkProfileError('country', 'required')">
                                    {{'enter_data' | translate}} <strong>{{'Country' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div class="reg-label">{{'City' | translate}} <span class="red-star">*</span></div>
                            <mat-form-field class="w-100" appearance="outline">
                                <input formControlName='city' matInput type="text" floatLabel='never' [matAutocomplete]="autoCities" />
                                <mat-autocomplete (optionSelected)="onCityChanged($event)" #autoCities="matAutocomplete" [displayWith]="displayName">
                                    <mat-option *ngFor="let item of filteredCities" [value]="item">
                                        {{item.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="checkProfileError('city', 'required')">
                                    {{'enter_data' | translate}} <strong>{{'City' | translate}}</strong>
                                </mat-error>
                            </mat-form-field>
                            <div class="reg-label">{{'Personal_information' | translate}}</div>
                            <mat-form-field class="w-100" fxFlex appearance="outline">
                                <textarea matInput
                                          [(ngModel)]='contact.description'
                                          formControlName='personalInformation' cdkTextareaAutosize
                                          cdkAutosizeMinRows="4" cdkAutosizeMaxRows="4"></textarea>
                            </mat-form-field>
                        </div>

                        <div fxFlex="31">
                            <div *ngIf="campaign?.course" fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                                <div class="mat-display-1 mb-2 accent-color pay-description">{{campaign?.course?.name}}</div>
                                <div fxFlex class="course-reg">
                                    <pre class="mat-body">{{campaign?.course?.description}}</pre>
                                    <hr class="delim w-100" />
                                    <div class="mat-body">{{'price' | translate}}: <b>{{campaign?.price | currency:'RUB' : 'symbol' :'1.2-2'}}</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 2vh;">
                        <button mat-raised-button mat-flat-button color="secondary"
                                class="b-secondary b-registration" (click)="saveProfile()">
                            {{'save_profile' | translate}}
                        </button>
                        <button *ngIf="campaign?.course" mat-raised-button mat-flat-button color="secondary"
                                class="b-primary b-registration" (click)="doPayment()">
                            {{'Proceed_to_checkout' | translate}}
                        </button>
                    </div>
                </form>
            </mat-step>
            <mat-step state="payment" label="{{'payment' | translate}}" errorMessage="">
                <div *ngIf="!isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'You_pay' | translate}}</div>
                <div *ngIf="!isPayed" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <div fxFlex="62" fxLayout="column">
                        <div class="mat-subheading-1 dark-accent-text-color" style="margin-top: 0.075vh;">{{'Payment_amount' | translate}} {{ campaign?.price }} {{ campaign?.currency?.symbol }}</div>
                        <div class="reg-label dark-text-color">{{'card_number' | translate}}</div>
                        <mat-form-field class="w-50 no-bottom" appearance="outline">
                            <input type="tel" mask="0000 0000 0000 0000" [showMaskTyped]="false"
                                   placeHolderCharacter="X" name="cnumber" #cnumber matInput
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]='buyModel.creditCard.cardNumber'
                                   class="w-100" placeholder="XXXX XXXX XXXX XXXX"
                                   required />-->
                            <!--div class="mat-body-2" *ngIf="isError">
                                {{errorMessage}}
                            </div-->
                        <!--</mat-form-field>
                        <div class="reg-label dark-text-color">{{'cardholder_name' | translate}}</div>
                        <div fxLayout="row" fxLayoutAlign="space-between stretch">
                            <mat-form-field fxFlex="50" class="w-100 no-bottom" appearance="outline">
                                <input [(ngModel)]='buyModel.creditCard.holderName'
                                       required matInput floatLabel='never' />
                            </mat-form-field>
                            <div class="mat-caption hint-color" fxFlex="48" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cardholder_desc' | translate}}</div></div>
                        </div>

                        <div fxLayout="row" class="w-50" fxLayoutAlign="start stretch">
                            <div fxFlex="54" class="reg-label dark-text-color">{{'validity' | translate}}</div>
                            <div fxFlex class="reg-label dark-text-color">{{'cvv' | translate}}</div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between stretch">
                            <div class="w-50" fxLayout="row" fxLayoutAlign="start stretch">
                                <mat-form-field class="card-date no-bottom" appearance="outline">
                                    <input type="tel"
                                           class="date-input"
                                           mask="00" [showMaskTyped]="false"
                                           placeHolderCharacter="X" name="validityMonth" #validityMonth matInput
                                           [(ngModel)]='buyModel.creditCard.expirationMonth'
                                           placeholder="{{'mm' | translate}}"
                                           required />
                                </mat-form-field>
                                <div class="card-date-delimiter mat-body-1">/</div>
                                <mat-form-field class="card-date no-bottom" appearance="outline">
                                    <input type="tel"
                                           class="date-input"
                                           mask="00" [showMaskTyped]="false"
                                           placeHolderCharacter="X" name="validityYear" #validityYear matInput
                                           placeholder="{{'yy' | translate}}"
                                           [(ngModel)]='buyModel.creditCard.expirationYear'
                                           required />
                                </mat-form-field>

                                <mat-form-field class="no-bottom cvv" appearance="outline">
                                    <input type="tel"
                                           mask="000" [showMaskTyped]="false"
                                           placeHolderCharacter="X" name="validityCvv" #validityCvv matInput
                                           placeholder="XXX"
                                           [(ngModel)]='buyModel.creditCard.cvv'
                                           required />
                                </mat-form-field>
                            </div>
                            <div fxFlex="48" class="mat-caption hint-color" fxLayout="column" fxLayoutAlign="center stretch"><div>{{'cvv_desc' | translate}}</div></div>
                        </div>

                        <div class="payments-methods" fxLayout="row" fxLayoutAlign="start center">
                            <div class="hint-color">{{'accept_for_payment' | translate}}</div>
                            <img style="margin-left:7px;" src="/images/Visa.png" alt="Visa" />
                            <img style="margin-left:14px;" src="/images/Mastercard.png" alt="Mastercard" />
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
                            <button mat-raised-button mat-flat-button color="secondary"
                                    class="b-primary b-registration" (click)="doPayment()">
                                {{'pay' | translate}}
                            </button>
                        </div>
                    </div>

                    <div fxFlex="31">
                        <div fxLayout="column h-100" fxLayoutAlign="space-between stretch">
                            <div class="mat-display-1 mb-2 accent-color">{{campaign?.course?.name}}</div>
                            <div fxFlex class="course-reg">
                                <pre class="mat-body">{{campaign?.course?.description}}</pre>
                                <hr class="delim w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isPayed" class="mat-body-1 font-weight-bold dark-text-color" style="margin-top: 2.2vh;">{{'Вы оплатили' | translate}}</div>
                <div *ngIf="isPayed" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div fxLayout="row" class="my-4" fxLayoutAlign="start center">
                        <img src="/images/complete.svg" alt="complete" />
                        <div fxFlex class="ml-3 reg-label dark-text-color font-weight-bold">{{'Оплата прошла успешно' | translate}}</div>
                    </div>
                    <div class="reg-label dark-text-color">{{'Вы оплатили' | translate}} {{campaign?.course?.name}}</div>
                    <div class="reg-label dark-text-color mt-2">{{'Сумма оплаты:' | translate}} <b>{{ campaign?.price }} {{ campaign?.currency?.symbol }}</b></div>
                    <a class="reg-label dark-text-color mt-4 cursorpointer">{{'Информация об оплате' | translate}}</a>

                    <div fxLayout="row" fxLayoutAlign="start stretch" style="margin-top: 4.73vh;">
                        <button mat-raised-button mat-flat-button color="secondary"
                                class="b-primary b-registration" (click)="goToCabinet()">
                            {{'Начать обучение' | translate}}
                        </button>
                    </div>
                </div>
            </mat-step>

        </mat-horizontal-stepper>
    </mat-card>
</div>-->
